// export const onClientEntry = () => {
//   // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
//   if (typeof window.IntersectionObserver === `undefined`) {
//     import(`intersection-observer`)
//     console.log(`# IntersectionObserver is polyfilled!`)
//   }
// }

exports.onRouteUpdate = () => {
   window.locations = window.locations || [document.referrer];
   locations.push(window.location.pathname);
   window.previousPath = locations[locations.length - 2];
};
